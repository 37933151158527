import { Controller } from "@hotwired/stimulus";
import { useMemo } from "stimulus-use";

export default class extends Controller {
  static values = {
    defaultList: {
      type: Array,
      default: []
    },
  }

  static memos = [
    'activityInput',
    'descriptionInput',
    'priceInput',
    'costInput'
  ]

  connect() {
    useMemo(this);
  
    if (!this.activityInput.value) { return }

    this._setPersistedActivity()
    this.populate()
  }

  populate(event) {
    let activity;
    const selectedActivity = this._findActivityBy(this._getIdFromGlobalId())

    if (this._selectedIsPersisted()) {
      activity = this.persistedActivity
    } else {
      activity = selectedActivity
    }

    this.descriptionInput.value = `${selectedActivity.name}: ${selectedActivity.description}`
    this._setPriceAndCost(activity, event)
  }

  _setPersistedActivity() {
    this.persistedActivity = {
      id: this._getIdFromGlobalId(),
      price_cents: parseInt(this.priceInput.value) * 100,
      cost_cents: parseInt(this.costInput.value) * 100
    }
  }

  _selectedIsPersisted() {
    return this._getIdFromGlobalId() == this.persistedActivity?.id
  }

  _findActivityBy(selectedActivityId) {
    return this.defaultListValue.find(({ id: activityId }) => {
      return activityId === selectedActivityId
    })
  }

  _getIdFromGlobalId() {
    const globalIdRoot = "gid://midstay-retreats/Offering::Activity/"
    return parseInt(this.activityInput.value.replace(globalIdRoot, ""))
  }

  _setPriceAndCost(activity, event) {
    if (event) {
      this.priceInput.value = (activity.price_cents / 100).toFixed(2)
      this.costInput.value = (activity.cost_cents / 100).toFixed(2)
    } else {
      this.priceInput.value ||= (activity.price_cents / 100).toFixed(2)
      this.costInput.value ||= (activity.cost_cents / 100).toFixed(2)
    }
  }

  get activityInput() {
    return this.element.querySelector('[name*="[itemizable_gid]"]')
  }

  get descriptionInput() {
    return this.element.querySelector('[name*="description"]')
  }

  get priceInput() {
    return this.element.querySelector('[name*="price"]')
  }

  get costInput() {
    return this.element.querySelector('[name*="cost"]')
  }
}