export function formatNumber(number) {
  return Intl.NumberFormat('en-US').format(number)
}

export function parseFloatFromString(value) {
  return parseFloat(value.replace(/,/g, ''))
}

export function sum(numbers) {
  return numbers.reduce((a, b) => a + b, 0)
}