import { Controller } from "@hotwired/stimulus";
import { calculateMarginAndPercentage } from "../helpers/marginCalculator";
import { parseFloatFromString, formatNumber } from "../helpers/number";

export default class extends Controller {
  static targets = ['price', 'cost', 'margin', 'actualCost']

  static values = {
    margin: Number,
    actualCost: Number
  }

  calculate() {
    const cost = parseFloatFromString(this.costTarget.value || 0)
    const price = (1 + this.marginValue) * cost
    this.priceTarget.value = price.toFixed(1)
    this.dispatch('calculated')
  }

  calculateMargin() {
    const price = parseFloatFromString(this.priceTarget.innerText);
    const actualCost = parseFloatFromString(this.actualCostTarget.value);
    const cost = parseFloatFromString(this.costTarget.innerText);

    const baseCost = actualCost > 0 ? actualCost : cost;
    const { margin, percentage } = calculateMarginAndPercentage(price, baseCost);

    if (this.marginTarget) {
      this.marginTarget.innerText = `${formatNumber(margin)} (${percentage}%)`;
    }
  }
}