import { Controller } from "@hotwired/stimulus";
import { calculateMarginAndPercentage } from "../helpers/marginCalculator";
import { formatNumber, parseFloatFromString } from "../helpers/number";

export default class extends Controller {
  static outlets = ['total-budget-calculator']
  static targets = ['price', 'cost', 'quantity', 'totalPrice', 'totalCost', 'margin', 'totalMargin']

  static values = {
    dailyMultiplier: {
      type: Number,
      default: 1
    },
    quantityMultiplier: {
      type: Number,
      default: 1
    }
  }

  connect() {
    this._calculateTotalCost()
    this._calculateTotalPrice()
    this._calculateMargin()
    this._calculateTotalMargin()
  }

  calculate() {
    this._calculateTotalCost()
    this._calculateTotalPrice()
    this._calculateMargin()
    this._calculateTotalMargin()
    this._calculateTotalBudget()
  }

  _calculateTotalCost() {
    if (this.hasTotalCostTarget) {
      this.totalCostTarget.innerText = formatNumber(this.totalCost)
    }
  }

  _calculateTotalPrice() {
    if (this.hasTotalPriceTarget) {
      this.totalPriceTarget.innerText = formatNumber(this.totalPrice)
    }
  }

  _calculateMargin() {
    if (this.hasMarginTarget) {
      const { margin, percentage } = calculateMarginAndPercentage(this.price, this.cost)
      this.marginTarget.innerText = `${formatNumber(margin)} (${percentage}%)`
      this.marginTarget.value = `${formatNumber(margin)} (${percentage}%)`
    }
  }

  _calculateTotalMargin() {
    if (this.hasTotalMarginTarget) {
      const { margin, percentage } = calculateMarginAndPercentage(this.totalPrice, this.totalCost)
      this.totalMarginTarget.innerText = `${formatNumber(margin)} (${percentage}%)`
    }
  }

  _calculateTotalBudget() {
    if (this.hasTotalBudgetCalculatorOutlet) {
      this.totalBudgetCalculatorOutlet.calculate()
    }
  }
  
  get quantity() {
    if (!this.hasQuantityTarget) {
      return this.quantityMultiplierValue
    }

    const value = this.quantityTarget.value

    if (value) {
      return parseFloatFromString(value) * this.quantityMultiplierValue
    } else {
      return this.quantityMultiplierValue
    }
  }

  get dailyMultiplier() {
    return this.dailyMultiplierValue
  }

  get price() {
    const value = this.priceTarget.value

    if (value) {
      return parseFloatFromString(value)
    } else {
      return 0
    }
  }

  get cost() {
    const value = this.costTarget.value

    if (value) {
      return parseFloatFromString(value)
    } else {
      return 0
    }
  }

  get totalPrice() {
    return this.price * this.quantity * this.dailyMultiplier
  }

  get totalCost() {
    return this.cost * this.quantity * this.dailyMultiplier
  }
}